<template>
  <div>
    <div class="search-bar">
      <a-form-model ref="searchRef"
                    :model="searchForm"
                    :labelCol="{ span: 6 }"
                    :wrapperCol="{ span: 18 }">
        <a-row>
          <a-col :span="8">
            <a-form-model-item label="留言类型" prop="type">
              <!-- 类型 (1-团购业务，2-产品咨询，3-业务咨询，4-投诉建议，5-其他) -->
              <a-select v-model="searchForm.type" @change="handlerSearch">
                <a-select-option value="">全部</a-select-option>
                <a-select-option
                    v-for="item in typeList"
                    :key="item.id"
                    :value="item.id"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <base-table ref="tableRef"
                :columnsData="columns"
                rowKey="id"
                :tableData="tableData"
                :total="searchForm.total"
                :getListFunc="initData"
                :page.sync="searchForm.page"
                :pageSize.sync="searchForm.page_count">
    </base-table>

  </div>
</template>

<script>
import { getList } from '@/api/system/msgboard'

export default {
  components: {  },
  data () {
    return {
      searchForm: {
        type: "",
        total: 0,
        page: 1,
        page_count: 20,
      },
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          align: "center",
          width: 80,
        },
        {
          title: "类型",
          dataIndex: "type",
          align: "center",
          width: 90,
          slots: {
            customRender: 'type'
          },
          slotsType: 'format',
          slotsFunc: (val) => this.formatType(val)
        },
        {
          title: "姓名",
          dataIndex: "name",
          align: "center",
          width: 100,
        },
        {
          title: "手机号",
          dataIndex: "phone",
          align: "left",
          width: 120,
        },
        {
          title: "邮箱",
          dataIndex: "email",
          align: "left",
          width: 200,
        },
        {
          title: "时间",
          dataIndex: "add_time",
          align: "left",
          width: 180,
        },
        {
          title: "留言",
          dataIndex: "content",
          align: "left",
        },
      ],
      
      typeList: [
        {
          id: 1,
          name: "团购业务",
        },{
          id: 2,
          name: "产品咨询",
        },{
          id: 3,
          name: "业务咨询",
        },{
          id: 4,
          name: "投诉建议",
        },{
          id: 5,
          name: "其他",
        },
      ],

      tableData: [],
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      const { data, code } = await getList(this.searchForm)
      if (code === 0) {
        this.tableData = data.list
        this.searchForm.total = data.total_count
      }
    },

    handlerSearch(val){
      console.log("handlerSearch", val)
      this.searchForm.page = 1
      this.initData()
    },

    formatType(type){
      // 类型 (1-团购业务，2-产品咨询，3-业务咨询，4-投诉建议，5-其他)
      const item = this.typeList.find(el=>{
        return el.id==type
      })
      return item ? item.name : ""
    },
  },
}
</script>

<style>
</style>