import ajax from '@/utils/ajax.js'

/**
 * 获取留言列表
 * @param {*} params 
 * @returns 
 */
export function getList(params) {
  return ajax.post('/MessageBoard/getList',params)
}
